import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useHistory, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Person from "@mui/icons-material/Person";
import Logout from "@mui/icons-material/Logout";
import Book from "@mui/icons-material/Book";
import ListIcon from "@mui/icons-material/List";
import HomeIcon from "@mui/icons-material/Home";
import useStyles from "./Style.js";
import Orders from "./Orders/View";
import AdminLedger from "./AdminLedger/View";
import WriterLedger from "./WriterLedger/View";
import Writers from "./Writers/View";
import Home from "./Home/View";
import StudentHome from "../Student/Home/View";
import StudentOrders from "../Student/Orders/View";
import WriterHome from "./WriterHome/View";
import WriterOrders from "./WriterOrders/View";
import AdminNotes from "./AdminNotes/View";
import Courses from "../Student/Courses/View";
import UsersManagment from "./UsersManagment/View";
import Colors from "../../../Utils/Colors";
import logo from "../../../assets/global/logo.png";
import { Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import AsyncStorage from "@callstack/async-storage";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Note from "@mui/icons-material/Note";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Money from "@mui/icons-material/Money";
import Students from "../Admin/Students/View";
import NotificationsScreen from "../../Notifications";
import { Button } from "@material-ui/core";

import { changeNotificationStatus } from "../../../Redux/actions";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#000",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniDrawer = ({ notifications, loading, changeNotificationStatus }) => {
  const theme = useTheme();
  const styles = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState("");
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [userId, setUserId] = useState(null);

  const location = useLocation();
  const [openNotifications, setOpenNotifications] = useState(false);

  const handleClickOpen = () => {
    setOpenNotifications(true);
  };

  const handleClose = () => {
    setOpenNotifications(false);
  };

 

  const init =async()=>{
    const id = await AsyncStorage.getItem("id");
    const role = await AsyncStorage.getItem("role");
    const loggedIn = await AsyncStorage.getItem("loggedIn");
    const loginDate = await AsyncStorage.getItem("loginDate");
    console.log("new Date() <= new Date(loginDate)");
    console.log("new Date() <= new Date(loginDate)",new Date());
    console.log("new Date() <= new Date(loginDate)",new Date(loginDate));
    console.log("new Date() <= new Date(loginDate)",new Date() <= new Date(loginDate));
    setLoggedIn(loggedIn);
    setRole(role);
    setUserId(id);
    if (loggedIn == "true") {
      if (loginDate && new Date() <= new Date(loginDate)) {
        if (
          location.pathname == "/dashboard/home" ||
          location.pathname == "/dashboard"
        ) {
          setSelectedIndex(0);
          setSelectedItem("Home");
        } else if (location.pathname == "/dashboard/orders") {
          setSelectedIndex(1);
          setSelectedItem("Orders");
        } else if (location.pathname == "/dashboard/order-management") {
          if (role != "writer") {
            setSelectedIndex(2);
            setSelectedItem("Order Management");
          } else {
            history.push("/notFound");
          }
        } else if (location.pathname == "/dashboard/writers") {
          if (role != "writer") {
            setSelectedIndex(3);
            setSelectedItem("Writers");
          } else {
            history.push("/notFound");
          }
        }
        else if (location.pathname == "/dashboard/students") {
          if (role != "writer") {
            setSelectedIndex(4);
            setSelectedItem("Students");
          } else {
            history.push("/notFound");
          }
        }
        else if (location.pathname == "/dashboard/writers-list") {
          if (role != "writer") {
            setSelectedIndex(5);
            setSelectedItem("Writers Management");
          } else {
            history.push("/notFound");
          }
        } else if (location.pathname == "/dashboard/users-management") {
          if (role != "writer") {
            setSelectedIndex(6);
            setSelectedItem("Users Management");
          } else {
            history.push("/notFound");
          }
        } else if (location.pathname == "/dashboard/notes") {
          if (role == "writer") {
            setSelectedIndex(2);
          } 
          else if (role == "student") {
            setSelectedIndex(3);
          } else {
            setSelectedIndex(7);
          }
          setSelectedItem("Notes");
        }
        else if (location.pathname == "/dashboard/Courses") {
          
           if (role == "student") {
            setSelectedIndex(2);
            setSelectedItem("Courses");

          } 
        }
        else if (location.pathname == "/dashboard/ledger") {
          if (role == "writer") {
            setSelectedIndex(3);
          } else {
            setSelectedIndex(8);
          }
          setSelectedItem("Ledger");
        } else {
          history.push("/notFound");
        }
      } else {

       logout();
      }
    } else {
      logout();
    }
  }

  useEffect(() => {
    init()
    }, []);

  const logout = async () => {
    await AsyncStorage.setItem("role", "");
    await AsyncStorage.setItem("email", "");
    await AsyncStorage.setItem("password", "");
    await AsyncStorage.setItem("loggedIn", "false");
    await AsyncStorage.setItem("name", "");
    await AsyncStorage.setItem("active", "");
    await AsyncStorage.setItem("noteShare", "");
    const loginPath = await AsyncStorage.getItem("loginPath");
    history.push(loginPath);
  };
console.log("selectedIndex",selectedIndex)
  const DrawerItems =
    role=="student"?[
      {
        name: "Home",
        Icon: (
          <HomeIcon
            style={{
              fontSize: "13px",
            }}
          />
        ),
        content: (
          <StudentHome
          openOrderManagement={() => {
            //setSelectedIndex(0);
            setSelectedItem("Orders");
            history.push("/dashboard/orders");
          }}
            role={role}
          />
        ),
        route: "/dashboard/home" || "/dashboard",
      },

      {
        name: "Orders",
        Icon: (
          <ListIcon
            style={{
              fontSize: "13px",
            }}
          />
        ),
        content: (
          <StudentOrders
            role={role}
            selectedNotification={selectedNotification}
          />
        ),
        route: "/dashboard/orders",
      },
      {
        name: "Courses",
        Icon: (
          <Book
            style={{
              fontSize: "13px",
            }}
          />
        ),
        content: <Courses role={role} />,
        route: "/dashboard/Courses",
      },
      {
        name: "Notes",
        Icon: (
          <Note
            style={{
              fontSize: "13px",
            }}
          />
        ),
        content: <AdminNotes role={role} />,
        route: "/dashboard/notes",
      },
    
      ,
      {
        name: "Logout",
        Icon: (
          <Logout
            style={{
              fontSize: "13px",
            }}
          />
        ),
      },
    ]:
    (role != "writer"
      ? [
          {
            name: "Home",
            Icon: (
              <HomeIcon
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: (
              <Home
                openOrderManagement={() => {
                  setSelectedIndex(2);
                  setSelectedItem("Order Management");
                  history.push("/dashboard/order-management");
                }}
                role={role}
              />
            ),
            route: "/dashboard/home" || "/dashboard",
          },

          {
            name: "Orders",
            Icon: (
              <ListIcon
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: (
              <Orders role={role} selectedNotification={selectedNotification} />
            ),
            route: "/dashboard/orders",
          },
          {
            name: "Order Management",
            Icon: (
              <ListIcon
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: (
              <iframe
                className={styles.iframe}
                src="https://ecwid-addons.s3.amazonaws.com/apps/customizations/17283181/document-upload/2.1/cp/index.html?lang=en#7b2273746f72655f6964223a31373238333138312c226163636573735f746f6b656e223a227365637265745f4e6b625046785832747576436d4a4455663131463246694e5852777a55515844222c227075626c69635f746f6b656e223a227075626c69635f646d4e3950736a7337326573486e736e54536a50647334566469584d4d724568222c22766965775f6d6f6465223a2250414745222c226c616e67223a22656e227d"
                title="Order Management"
              ></iframe>
            ),
            route: "/dashboard/order-management",
          },

          {
            name: "Writers",
            Icon: (
              <Person
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <Writers role={role} openOrderDetails2={(order) => {
              setSelectedOrder(order);
              global.order = order;
              setSelectedIndex(1);
              history.replace("/dashboard/orders");
            }} 
            />,
            route: "/dashboard/writers",
          },
          {
            name: "Students",
            Icon: (
              <Person
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <Students role={role} openOrderDetails2={(order) => {
              setSelectedOrder(order);
              global.order = order;
              setSelectedIndex(1);
              history.replace("/dashboard/orders");
            }} 
            />,
            route: "/dashboard/students",
          },
          {
            name: "Writers Management",
            Icon: (
              <Person
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: (
              <iframe
                className={styles.iframe}
                src="https://ecwid-addons.s3.amazonaws.com/apps/customizations/17283181/writers-list/1.7/cp/index.html?&lang=en#7b2273746f72655f6964223a31373238333138312c226163636573735f746f6b656e223a227365637265745f624461555769734533717876614c55384673667a5067545566695a454a6d7341222c22766965775f6d6f6465223a2250414745222c226c616e67223a22656e227d"
                title="Writers List"
              ></iframe>
            ),
            route: "/dashboard/writers-list",
          },

          {
            name: "Users Management",
            Icon: (
              <Person
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <UsersManagment role={role} />,
            route: "/dashboard/users-management",
          },

          {
            name: "Notes",
            Icon: (
              <Note
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <AdminNotes role={role} />,
            route: "/dashboard/notes",
          },
          {
            name: "Ledger",
            Icon: (
              <Money
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <AdminLedger role={role}  openOrderDetails2={(order) => {
              setSelectedOrder(order);
              global.order = order;
              setSelectedIndex(1);
              history.replace("/dashboard/orders");
            }} />,
            route: "/dashboard/ledger",
          },
          {
            name: "Logout",
            Icon: (
              <Logout
                style={{
                  fontSize: "13px",
                }}
              />
            ),
          },
        ]
      : [
          {
            name: "Home",
            Icon: (
              <HomeIcon
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: (
              <WriterHome
                openOrderDetails2={(order) => {
                  setSelectedOrder(order);
                  global.order = order;
                  setSelectedIndex(1);
                  history.replace("/dashboard/orders");
                }}
                role={role}
              />
            ),
            route: "/dashboard/home" || "/dashboard",
          },

          {
            name: "Orders",
            Icon: (
              <ListIcon
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: (
              <WriterOrders
                role={role}
                selectedNotification={selectedNotification}
              />
            ),
            route: "/dashboard/orders",
          },
          {
            name: "Notes",
            Icon: (
              <Note
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <AdminNotes role={role} />,
            route: "/dashboard/notes",
          },
          {
            name: "Ledger",
            Icon: (
              <Money
                style={{
                  fontSize: "13px",
                }}
              />
            ),
            content: <WriterLedger role={role} />,
            route: "/dashboard/ledger",
          },
          {
            name: "Logout",
            Icon: (
              <Logout
                style={{
                  fontSize: "13px",
                }}
              />
            ),
          },
        ]);

  const [selectedItem, setSelectedItem] = React.useState(DrawerItems[0]?.name);

  return loggedIn == "true" ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: Colors.blue }}
        position="fixed"
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            //onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            style={{ width: "200px" }}
            noWrap
            component="div"
          >
            {selectedItem || ""}
          </Typography>

          <div style={{ marginLeft: "70%" }}>
            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.grey6,
                transition: { duration: 0.3 },
              }}
              onClick={() => {
                handleClickOpen();
              }}
              className={styles.notificationButton}
            >
              <NotificationsIcon /> Notifications
            </Button>

          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ alignSelf: "flex-start" }}>
          <img
            src={logo}
            style={{ width: "50px", height: "50px", borderRadius: "5px" }}
          />
          <Grid container direction="row">
            <Typography
              style={{
                fontFamily: "Spartan",
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "20px",
                color: Colors.white,
              }}
              noWrap
              component="div"
            >
              A+ Paper Writer
            </Typography>{" "}
            <Typography
              style={{
                fontFamily: "Spartan",
                fontSize: "11px",
                marginLeft: "20px",
                color: Colors.white,
              }}
              noWrap
              component="div"
            >
              Dashboard
            </Typography>
            <Typography
              style={{
                fontFamily: "Spartan",
                fontSize: "9px",
                marginLeft: "20px",
                color: Colors.green,
                fontWeight: "bold",
              }}
              noWrap
              component="div"
            >
              {String(role).toLocaleUpperCase()}
            </Typography>
          </Grid>
        </DrawerHeader>
        <Divider style={{ color: Colors.grey }} />
        <List style={{ marginTop: "30px" }}>
          {DrawerItems.map((item, index) => (
            <ListItem
              component={motion.div}
              whileHover={{
                backgroundColor: Colors.dark,
                transition: { duration: 0.3 },
                color: Colors.white,
              }}
              style={{
                backgroundColor:
                  selectedIndex == index ? Colors.blue : Colors.none,
              }}
              key={item.name}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={async () => {
                  setSelectedIndex(index);

                  if (item.name == "Logout") {
                    logout();
                  } else {
                    history.push(item.route);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",

                    color: Colors.white,
                    fontFamily: "Spartan",
                  }}
                >
                  {item.Icon}
                </ListItemIcon>
                <Typography
                  style={{
                    color: Colors.white,
                    fontFamily: "Spartan",
                    fontSize: "11px",
                    fontWeight: "bold",
                  }}
                  sx={{ opacity: open ? 1 : 0 }}
                >
                  {item.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider style={{ color: Colors.grey }} />
      </Drawer>
      <div component="main" style={{ marginTop: "70px" }}>
        {DrawerItems[selectedIndex].content}
      </div>

      {openNotifications ? (
        <NotificationsScreen
          selectNotification={(item) => {
            setOpenNotifications(false);
            setSelectedNotification(item);
            global.selectedNotification = item;
            changeNotificationStatus(userId, item.id, () => {});
            setSelectedIndex(1);
            history.replace("/dashboard/orders");
          }}
          handleClose={() => {
            handleClose();
          }}
        />
      ) : null}
    </Box>
  ) : (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress
        style={{ alignSelf: "center", color: Colors.blue, marginTop: "100px" }}
      />
    </div>
  );
};

const mapStateToProps = ({ notifications }) => {
  return {
    loading: notifications.loadingNotifications,
    notifications: notifications.notifications,
  };
};

export default connect(mapStateToProps, {
  changeNotificationStatus,
})(MiniDrawer);
