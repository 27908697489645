import {
  GET_ADMIN_LEDGER_LIST_ATTEMPING,
  GET_ADMIN_LEDGER_LIST_FAILED,
  GET_ADMIN_LEDGER_LIST_SUCCESS,
  SAVE_ADMIN_LEDGER_ITEM_ATTEMPING,
  SAVE_ADMIN_LEDGER_ITEM_FAILED,
  SAVE_ADMIN_LEDGER_ITEM_SUCCESS,
  DELETE_ADMIN_LEDGER_ITEM_ATTEMPING,
  DELETE_ADMIN_LEDGER_ITEM_FAILED,
  DELETE_ADMIN_LEDGER_ITEM_SUCCESS,
  GET_ORDER_LEDGER_ATTEMPING,
  GET_ORDER_LEDGER_FAILED,
  GET_ORDER_LEDGER_SUCCESS,
} from "./Types";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";
import Colors from "../../Utils/Colors";
import firebase from "../../global/services/Firebase";

export const getOrderLedger = (email, dateFrom, dateTo) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ORDER_LEDGER_ATTEMPING,
    });

    try {
      let result = [];

      var config = {
        method: "get",
        url: ServiceURL.Orders(),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      let total = 0.0;

      data.items.filter((order) => {
        var text = order?.privateAdminNotes;
        var orderEmail = String(text).match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );

        if (orderEmail && orderEmail[orderEmail.length - 1] == email) {
          order?.items?.map((item) => {
            let pageCount = 0;
            let isInRush = false;

            item?.selectedOptions?.filter((option) => {
              if (option.name == "Page Count" ) {
                pageCount = parseInt(option.value);
              }
              if (option.name =="Slide Count") {
                pageCount = parseInt(option.value);
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("1 day")
              ) {
                isInRush = true;
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("Same day")
              ) {
                isInRush = true;
              }
            });
            if (isInRush) {
              total += 7 * pageCount;
            } else {
              total += 6 * pageCount;
            }
          });
          if (
            new Date(new Date(order.createDate).toDateString()) >=
              new Date(new Date(dateFrom).toDateString()) &&
            new Date(new Date(order.createDate).toDateString()) <=
              new Date(new Date(dateTo).toDateString())
          ) {
            let orderTotal = 0.0;
            let dueDate = "";

            order?.items?.map((item) => {
              let pageCount = 0;
              let isInRush = false;

              item?.selectedOptions?.filter((option) => {
                if (String(option.name).includes("Due Date")) {
                  dueDate = option.value;
                }

                if (option.name == "Page Count") {
                  pageCount = parseInt(option.value);
                }
                if (option.name =="Slide Count") {
                  pageCount = parseInt(option.value);
                }
                if (
                  option.name == "Rush Service" &&
                  option.value.includes("1 day")
                ) {
                  isInRush = true;
                }
                if (
                  option.name == "Rush Service" &&
                  option.value.includes("Same day")
                )
                
                {
                  isInRush = true;
                }
              });
              if (isInRush) {
                orderTotal += 7 * pageCount;
              } else {
                orderTotal += 6 * pageCount;
              }
            });

            order.total = orderTotal;

            result.push({
              order: order,
              date: order.createDate,
              status: order.fulfillmentStatus,
              price: orderTotal,
              name: order.id,
              type: "Order(" + order.items[0].name + ")",
              dueDate: dueDate,
            });
          }
        }
      });

      dispatch({
        type: GET_ORDER_LEDGER_SUCCESS,
        payload: result,
        payload2: total,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_LEDGER_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const getWriterLedger = (query, email, dateFrom, dateTo) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ADMIN_LEDGER_LIST_ATTEMPING,
    });

    try {
      let result = [];
      let total = 0.0;
      firebase
        .database()
        .ref("dashboard/users/")
        .on("value", (snapshot) => {
          snapshot.forEach((value) => {
            let user = value.val();
            if (user.role == "writer" && user?.payments) {
              const values = Object.values(user?.payments);

              values?.forEach((payment) => {
                total += parseFloat(payment.price);

                if (payment.writerEmail == email) {
                  if (
                    new Date(new Date(payment.date).toDateString()) >=
                      new Date(new Date(dateFrom).toDateString()) &&
                    new Date(new Date(payment.date).toDateString()) <=
                      new Date(new Date(dateTo).toDateString())
                  ) {
                    if (
                      String(payment.item)
                        .replace(" ", "")
                        .toUpperCase()
                        .includes(
                          String(query).toUpperCase().replace(" ", "")
                        ) ||
                      String(payment.price)
                        .replace(" ", "")
                        .toUpperCase()
                        .includes(String(query).toUpperCase().replace(" ", ""))
                    ) {
                      result.push({
                        date: payment.date,
                        price: payment.price,
                        name: payment.item,
                        note: payment.note,
                        type: `Payment`,
                      });
                    }
                  }
                }
              });
            }
          });
        });

      var config = {
        method: "get",
        url: ServiceURL.Orders(),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);

      data.items.filter((order) => {
        var text = order?.privateAdminNotes;
        var orderEmail = String(text).match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );

        if (orderEmail && orderEmail[orderEmail.length - 1] == email) {
          order?.items?.map((item) => {
            let pageCount = 0;
            let isInRush = false;

            item?.selectedOptions?.filter((option) => {
              if (option.name == "Page Count") {
                pageCount = parseInt(option.value);
              }
              if (option.name =="Slide Count") {
                pageCount = parseInt(option.value);
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("1 day")
              ) {
                isInRush = true;
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("Same day")
              )
              
              {
                isInRush = true;
              }
            });
            if (isInRush) {
              total += 7 * pageCount;
            } else {
              total += 6 * pageCount;
            }
          });

          if (
            new Date(new Date(order.createDate).toDateString()) >=
              new Date(new Date(dateFrom).toDateString()) &&
            new Date(new Date(order.createDate).toDateString()) <=
              new Date(new Date(dateTo).toDateString())
          ) {
            let total = 0;

            order?.items?.map((item) => {
              let pageCount = 0;
              let isInRush = false;

              item?.selectedOptions?.filter((option) => {
                if (String(option.name).includes("Due Date")) {
                  order.dueDate = option.value;
                }
                if (option.name == "Page Count") {
                  pageCount = parseInt(option.value);
                }
                if (option.name =="Slide Count") {
                  pageCount = parseInt(option.value);
                }
                if (
                  option.name == "Rush Service" &&
                  option.value.includes("1 day")
                ) {
                  isInRush = true;
                }
                if (
                  option.name == "Rush Service" &&
                  option.value.includes("Same day")
                )
                
                {
                  isInRush = true;
                }
              });
              if (isInRush) {
                total += 7 * pageCount;
              } else {
                total += 6 * pageCount;
              }
            });

            if (
              String(order.id)
                .replace(" ", "")
                .toUpperCase()
                .includes(String(query).toUpperCase().replace(" ", "")) ||
              String("Order(" + order.items[0].name + ")")
                .replace(" ", "")
                .toUpperCase()
                .includes(String(query).toUpperCase().replace(" ", ""))
            ) {
              result.push({
                date: order.createDate,
                price: total,
                name: order.id,
                note: "",
                type: "Order(" + order.items[0].name + ")",
              });
            }
          }
        }
      });

      dispatch({
        type: GET_ADMIN_LEDGER_LIST_SUCCESS,
        payload: result,
        payload2: total,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_LEDGER_LIST_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const deleteAdminLedgerItem = (paymentId, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ADMIN_LEDGER_ITEM_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/adminLedger/" + paymentId)
        .remove()
        .then((response) => {
          callBack(true);
          dispatch({
            type: DELETE_ADMIN_LEDGER_ITEM_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: DELETE_ADMIN_LEDGER_ITEM_FAILED });
    }
  };
};

export const createManaulLedgerItem = (
  id,
  userName,
  role,
  email,
  date,
  name,
  type,
  price,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: SAVE_ADMIN_LEDGER_ITEM_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/adminLedger/")
        .push({ id, userName, role, email, name, date, price, type })
        .then((response) => {
          firebase
            .database()
            .ref("dashboard/adminLedger/" + response.key)
            .update({
              id: response.key,
            });
          callBack(true);
          dispatch({
            type: SAVE_ADMIN_LEDGER_ITEM_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: SAVE_ADMIN_LEDGER_ITEM_FAILED });
    }
  };
};

export const getAdminLedger = (query, dateFrom, dateTo) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ADMIN_LEDGER_LIST_ATTEMPING,
    });

    try {
      let result = [];
      let total = 0.0;
      let ledger_balance = 0.0;

      firebase
        .database()
        .ref("dashboard/users/")
        .on("value", (snapshot) => {
          snapshot.forEach((value) => {
            let user = value.val();
            if (user.role == "writer" && user?.payments) {
              const values = Object.values(user?.payments);

              values?.forEach((payment) => {
                total += parseFloat(payment.price);
                ledger_balance += parseFloat(payment.price);

                if (
                  new Date(new Date(payment.date).toDateString()) >=
                    new Date(new Date(dateFrom).toDateString()) &&
                  new Date(new Date(payment.date).toDateString()) <=
                    new Date(new Date(dateTo).toDateString())
                ) {
                  if (
                    String(payment.item)
                      .replace(" ", "")
                      .toUpperCase()
                      .includes(String(query).toUpperCase().replace(" ", "")) ||
                    String(
                      `Payment` +
                        (payment.writerEmail
                          ? "(" + payment.writerEmail + ")"
                          : "")
                    )
                      .replace(" ", "")
                      .toUpperCase()
                      .includes(String(query).toUpperCase().replace(" ", ""))
                  ) {
                    result.push({
                      id: payment.id,
                      date: new Date(
                        new Date(payment.date).toLocaleString("en-US", {
                          timeZone: "America/New_York"
                        })
                      )
                        .toString()
                        .substr(0, 24),
                      order_reference: payment.item,
                      writer_payout:0,
                      type:
                     //   `Payment` +
                        (payment.writerEmail
                          ? "(" + payment.writerEmail + ")"
                          : ""),
                          gross_payment: payment.price,

                    });
                  }
                }
              });
            }
          });
        });

      firebase
        .database()
        .ref("dashboard/adminLedger/")
        .on("value", (snapshot) => {
          snapshot.forEach((value) => {
            let adminLedger = value.val();
            total += parseFloat(adminLedger.price);
            
            if (
              new Date(new Date(adminLedger.date).toDateString()) >=
                new Date(new Date(dateFrom).toDateString()) &&
              new Date(new Date(adminLedger.date).toDateString()) <=
                new Date(new Date(dateTo).toDateString())
            ) {
              if (
                String(adminLedger.name)
                  .replace(" ", "")
                  .toUpperCase()
                  .includes(String(query).toUpperCase().replace(" ", "")) ||
                String(adminLedger.type)
                  .replace(" ", "")
                  .toUpperCase()
                  .includes(String(query).toUpperCase().replace(" ", ""))
              ) {
                result.push({
                  id: adminLedger.id,
                  date: new Date(
                    new Date(adminLedger.date).toLocaleString("en-US", {
                      timeZone: "America/New_York"
                    })
                  )
                    .toString()
                    .substr(0, 24),
                  order_reference: adminLedger.name,
                  type: adminLedger.type,
                  writer_payout:0,
                  manual: true,
                  gross_payment: adminLedger.price,

                });
              }
            }
          });
        });

      var config = {
        method: "get",
        url: ServiceURL.Orders(),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);

      data.items.filter((order) => {
        var text = order?.privateAdminNotes;
        var orderEmail = String(text).match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );
        
        order?.items?.map((item) => {
          total += parseFloat(item?.price);

          order?.items[0]?.selectedOptions?.map((option) => {
            if (option.type == "CHOICE") {
              option?.selections?.map((selection) => {
                total += selection?.selectionModifier;
              });
            }
          });
        });

        if (
          new Date(new Date(order.createDate).toDateString()) >=
            new Date(new Date(dateFrom).toDateString()) &&
          new Date(new Date(order.createDate).toDateString()) <=
            new Date(new Date(dateTo).toDateString())
        ) {
          let total = 0.0;
          let writer_payout = 0.0;
          order?.items?.map((item) => {
            total += parseFloat(item?.price);
            ledger_balance+= parseFloat(item?.price);
            order?.items[0]?.selectedOptions?.map((option) => {
              if (option.type == "CHOICE") {
                option?.selections?.map((selection) => {
                  total += selection?.selectionModifier;
                });
              }
            });

            let pageCount = 0;
            let isInRush = false;

            item?.selectedOptions?.filter((option) => {
              if (option.name == "Page Count" ) {
                pageCount = parseInt(option.value);
              }
              if (option.name =="Slide Count") {
                pageCount = parseInt(option.value);
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("1 day")
              ) {
                isInRush = true;
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("Same day")
              ) {
                isInRush = true;
              }
            });
            if (isInRush) {
              writer_payout += 7 * pageCount;
            } else {
              writer_payout += 6 * pageCount;
            }
          });
          
          if (
            String(order.id)
              .replace(" ", "")
              .toUpperCase()
              .includes(String(query).toUpperCase().replace(" ", "")) ||
            String("Order(" + order.items[0].name + ")")
              .replace(" ", "")
              .toUpperCase()
              .includes(String(query).toUpperCase().replace(" ", ""))
          ) {
            result.push({
              date: new Date(
                new Date(order.createDate).toLocaleString("en-US", {
                  timeZone: "America/New_York"
                })
              )
                .toString()
                .substr(0, 24),
              order_reference: order.id,
              type: "Order(" + order.items[0].name + ")",
              writer_assigned:orderEmail[0],
              writer_payout:writer_payout,
              order:order,
              gross_payment: total,

            });
          }
        }
      });

      dispatch({
        type: GET_ADMIN_LEDGER_LIST_SUCCESS,
        payload: result,
        payload2: total,
        payload3:ledger_balance
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_LEDGER_LIST_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};
