export const pageCountChoices= [
    {
      text: "1",
      textTranslated: {
        en: "1",
      },
      priceModifier: 10,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "2",
      textTranslated: {
        en: "2",
      },
      priceModifier: 20,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "3",
      textTranslated: {
        en: "3",
      },
      priceModifier: 30,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "4",
      textTranslated: {
        en: "4",
      },
      priceModifier: 40,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "5",
      textTranslated: {
        en: "5",
      },
      priceModifier: 50,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "6",
      textTranslated: {
        en: "6",
      },
      priceModifier: 60,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "7",
      textTranslated: {
        en: "7",
      },
      priceModifier: 70,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "8",
      textTranslated: {
        en: "8",
      },
      priceModifier: 80,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "9",
      textTranslated: {
        en: "9",
      },
      priceModifier: 90,
      priceModifierType: "ABSOLUTE",
    },
    {
      text: "10",
      textTranslated: {
        en: "10",
      },
      priceModifier: 125,
      priceModifierType: "ABSOLUTE",
    },
    {
        text: "11",
        textTranslated: {
          en: "11",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },

      {
        text: "12",
        textTranslated: {
          en: "12",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "13",
        textTranslated: {
          en: "13",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "14",
        textTranslated: {
          en: "14",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "15",
        textTranslated: {
          en: "15",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "16",
        textTranslated: {
          en: "16",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "17",
        textTranslated: {
          en: "17",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "18",
        textTranslated: {
          en: "18",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "19",
        textTranslated: {
          en: "19",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
      {
        text: "120",
        textTranslated: {
          en: "20",
        },
        priceModifier: 125,
        priceModifierType: "ABSOLUTE",
      },
  ]