import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  homeStats,
  getProducts,
  deleteNotification
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import ProductItem from "../../../../Components/ProductItem";
import ProductForm from "../ProductForm/View";

const Home = ({
  homeStats,
  loadingOrdersStats,
  orderStats,
  getProducts,
  products,
  loading,
  role,
  deleteNotification,
  openOrderManagement
}) => {
  const styles = useStyles();
  const [showStats, setShowStats] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(async () => {
    //deleteNotification()
    homeStats("", "", "", "");
    getProducts();
  }, []);

  const Item = (children, value) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px"
        }}
        className={styles.container2}
        container
        direction="column"
      >
        {children}

        {loadingOrdersStats ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };

  return showStats ? (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        {Item(
          <Typography className={styles.numberText}>Total Orders</Typography>,
          orderStats?.totalOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Delivered Orders
          </Typography>,
          orderStats?.deliveredOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Processing Orders
          </Typography>,
          orderStats?.processingOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Awaiting Processing Orders
          </Typography>,
          orderStats?.awaitingProcessingOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>Canceled</Typography>,
          orderStats?.willNotDeliverOrdersCount
        )}

        {role == "admin"
          ? Item(
              <Typography className={styles.numberText}>Total Paid</Typography>,
              "$" + Math.round(orderStats?.totalPaid).toLocaleString()
            )
          : null}
      </Grid>

      <Typography className={styles.idOrder}>Products</Typography>
      {loading ? (
        <Box className={styles.progress}>
          <CircularProgress
            style={{ alignSelf: "center", color: Colors.blue }}
          />
        </Box>
      ) : products?.length > 0 ? (
        <Grid container direction="row">
          {products?.sort((a,b) => a.name - b.name)?.map((item, index) => {
            if (
              item?.id == 694325716 ||
              item?.id == 392168910 ||
              item?.id == 139667022 ||
              item?.id == 409159815 ||
              item?.id == 694315665
            ) {
              return (
                <Grid item>
                  <ProductItem
                    key={index.toString()}
                    product={item}
                    buyAction={() => {
                      setSelectedProduct(item);
                      setShowStats(false);
                    }}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      ) : null}
    </div>
  ) : (
    <ProductForm
      role={role}
      openOrderManagement={openOrderManagement}
      onClick={() => setShowStats(true)}
      formData={selectedProduct}
    />
  );
};

const mapStateToProps = ({ products, orders }) => {
  return {
    loadingOrdersStats: orders.loadingOrdersStats,
    errorOrdersStats: orders.errorOrdersStats,
    orderStats: orders.orderStats,
    products: products.products,
    loading: products.loading
  };
};

export default connect(mapStateToProps, {
  homeStats,
  getProducts,
  deleteNotification
})(Home);
